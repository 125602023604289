import React, { useEffect, useState, useCallback } from 'react';
import { auth } from '../../configurations/firebaseConfig';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, User } from 'firebase/auth';
import { Button, Text, Box, VStack, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import appInsights from '../../configurations/appInsights';
import { THEME } from '../../theme/colors';

// Detect common in-app browsers to prevent sign-in issues and ensure proper functionality
const isInAppBrowser = () => {
  const ua = navigator.userAgent;
  const browserRegex = /\b(FBAN|FBAV|Instagram|Line|Twitter|Snapchat|Pinterest|DuckDuckGo|LinkedIn)\b/i;
  
  // Check for Edge explicitly
  const isEdge = /\bEdg\b/.test(ua);

  // General check for WebView-based browsers, excluding known safe browsers
  const isGenericWebView = ua.includes('Mozilla/5.0') && 
                           ua.includes('AppleWebKit') && 
                           !ua.includes('Chrome') && 
                           !ua.includes('Safari') &&
                           !isEdge;

  return browserRegex.test(ua) || isGenericWebView;
};

interface SignInProps {
  buttonText?: string;
  className?: string;
}

const SignIn: React.FC<SignInProps> = ({ buttonText = "Sign in with Google", className }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);

  const handleAuthStateChange = useCallback(async (user: User | null) => {
    if (user) {
      try {
        const idToken = await user.getIdToken();
        const response = await axios.post('/initialize', {}, { headers: { Authorization: `Bearer ${idToken}` } });
        if (response.data.profileSubmitted) {
          navigate('/chat');
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error("Error during initialization: ", error);
        toast({
          title: "Initialization Failed",
          description: "Unable to initialize chat. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  }, [navigate, toast]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  const signInWithGoogle = useCallback(() => {
    if (isInAppBrowser()) {
      setShowBrowserWarning(true);
      return;
    }
    setIsModalOpen(true);
  }, []);

  const handlePrivacyAgree = useCallback(() => {
    setIsModalOpen(false);
    appInsights.trackEvent({ name: 'SignInWithGoogleClicked' });
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Error during Google sign in: ", error);
      toast({
        title: "Sign in failed",
        description: "There was an issue with Google sign in. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });
  }, [toast]);

  if (isLoading) {
    return <Spinner size="xl" color={THEME.primary} />;
  }

  return (
    <div className={className}>
      <Button
        w="200px"
        bg={THEME.primary}
        color="white"
        h="50px"
        size="lg"
        mb="10"
        _hover={{
          bg: THEME.colors.hover,
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
        }}
        _active={{
          bg: THEME.colors.hover,
          transform: 'translateY(0)',
        }}
        transition="all 0.2s"
        onClick={signInWithGoogle}
      >
        {buttonText}
      </Button>

      {/* Privacy Agreement Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Agreement</ModalHeader>
          <ModalBody py={6}>
            <VStack spacing={3} align="start">
              <Text>Welcome! Before you get started, just a quick nod to our privacy practices:</Text>
              <Box as="ul" pl={5}>
                <Text as="li">Your data is securely transmitted and stored.</Text>
                <Text as="li">We do not sell or share your data with third parties.</Text>
                <Text as="li">You have the right to request the deletion of your data at any time.</Text>
              </Box>
              <Text fontSize="sm" color="gray.600" marginTop={5}>
                For more details, please read our{' '}
                <Text 
                  as="span" 
                  color={THEME.primary} 
                  cursor="pointer" 
                  _hover={{ textDecoration: 'underline' }}
                  onClick={() => navigate('/privacy-policy')}
                >
                  Privacy Policy
                </Text>.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              bg={THEME.primary}
              color="white"
              mr={3}
              onClick={handlePrivacyAgree}
              _hover={{
                bg: THEME.colors.hover,
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
              _active={{
                bg: THEME.colors.hover,
                transform: 'translateY(0)',
              }}
              transition="all 0.2s"
            >
              I Agree
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Browser Warning Modal */}
      <Modal isOpen={showBrowserWarning} onClose={() => setShowBrowserWarning(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Browser Not Secure</ModalHeader>
          <ModalBody>
            <Text>
              Please use a standard web browser like Safari, Chrome or Edge to sign in.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SignIn;
