import { Box, Container } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const privacyPolicyContent = `
# Privacy Policy
**Last Updated: December 22nd, 2024**

Healoop, Inc ("the Company") is committed to maintaining robust privacy protections for its users. This Privacy Policy is designed to help you understand how we collect, use, store, and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.

For purposes of this Agreement:  
- **"Site"** refers to the Company's website, accessible at [https://healoop.app](https://healoop.app).  
- **"Service"** refers to the Company's services accessed via the Site.  
- **"We," "us," and "our"** refer to the Company.  
- **"You"** refers to you, as a user of our Site or Service.  

By accessing our Site or Service, you accept this Privacy Policy and our [Terms of Use](#), and you consent to our collection, storage, use, and disclosure of your Personal Information as described herein.

---

## I. INFORMATION WE COLLECT

We collect two types of information:  
1. **Non-Personal Information**: Information that cannot identify you, such as anonymous usage data, general demographic information, referring/exit pages, and preferences generated from your use of the Service.  
2. **Personal Information**: Information that may identify you, such as email address, date of birth, gender, health interests, medical summary, medical-related questions, and any medical files you upload.  

### 1. Information You Provide  
- **Sign-Up and Profile Information**: During registration or profile updates, we collect your email, age, gender, health interests, and a free-form medical summary that you provide. The medical summary may contain personal information depending on what you choose to share. We treat all information you provide with appropriate security measures and use it only to deliver our services.  
- **Connected Health Data**: Data accessed via integrations like MyChart is valid for a single user session, is analyzed to provide insights, but is never persisted.  
- **Uploaded Files**: Files are processed over the network and their summaries are stored locally on your device. The files themselves are never stored on our servers.

### 2. Information Collected via Technology  
We automatically collect certain Non-Personal Information to enhance your experience:  
- Browser type, device type, and operating system.  
- Referring URL and other website usage patterns.  
- Cookies, which store anonymous unique identifiers for functionality and analytics.  
- User preferences to improve the Service.  

You can disable cookies via your browser settings, but doing so may affect the functionality of the Service.

---

## II. HOW WE USE YOUR INFORMATION

### Personal Information  
We only store information provided during sign-up or when updating your profile. This includes:  
- Age  
- Gender  
- Health Interests  
- Medical Summary  

This information is used to:  
1. Enable access to your account across all your devices without requiring re-entry of information
2. Maintain consistent service delivery regardless of which device you use
3. Provide personalized health insights based on your profile
4. Support account management and customer service

### Information Processed but Not Persisted  
To maintain privacy, the following data is processed temporarily without being stored on our servers:  
1. **Chat Messages**:  
   - Your messages are processed securely over our network using an AI model
   - Each conversation is private and personalized to you
   - Our AI service provider processes these messages in real-time without storing or accessing your data
   - For your convenience, chat history is stored locally on your device, allowing you to review past conversations

2. **Uploaded Files**:  
   - Files are processed over the network and their summaries are stored locally on your device. The files themselves are never stored on our servers.  

3. **Connected Health Data**:  
   - Data accessed via integrations like MyChart is valid for a single user session, is analyzed to provide insights, but is never persisted.  

---

## III. HOW WE SHARE YOUR INFORMATION

### Personal Information  
We do not sell, trade, or rent your Personal Information. We handle your data with utmost care:
1. **Email Communications**: Your email address may be processed by our email service provider solely to send you important account-related communications.
2. **Health Information**: Your health-related information and medical summary are never shared with third parties.
3. **Legal Requirements**: In rare cases, we may be required to disclose information to comply with applicable laws or valid legal requests. Any such disclosure will be limited to the specific information legally required.

### Non-Personal Information  
Aggregated and anonymized data may be shared with partners to improve our services and monitor usage trends.

---

## IV. HOW WE PROTECT INFORMATION

We employ industry-standard security measures to protect your information, including:  
- Encryption, firewalls, and secure socket layer (SSL) technology.  
- Utilizing modern cloud technologies that are built for HIPAA compliance.  
- Password protection for your account.  

Despite these measures, no security system is completely foolproof. By using the Service, you acknowledge and assume these risks.

---

## V. YOUR RIGHTS REGARDING PERSONAL INFORMATION

- **Access & Control**: You can review, update, or delete your data at any time by logging into your account or contacting us at deji@healoop.app.  
- **Opt-Out**: You can opt out of marketing communications via unsubscribe links or by emailing us.  

---

## VI. LINKS TO OTHER WEBSITES

Our Service may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. This Privacy Policy applies solely to information collected through our Site and Service.

---

## VII. CHANGES TO OUR PRIVACY POLICY

We may update this Privacy Policy from time to time. Significant changes will be communicated via email or a notice on our Site at least 30 days before they take effect. Non-material changes or clarifications will take effect immediately. Please periodically review this page for updates.

---

## VIII. CONTACT US

If you have questions or concerns about this Privacy Policy, contact us at:  
**Email**: deji@healoop.app  
`;

const PrivacyPolicyPage = () => {
  return (
    <Container maxW="container.lg" py={8}>
      <Box 
        bg="white" 
        p={8} 
        borderRadius="lg" 
        boxShadow="sm"
        className="markdown-content"
        sx={{
          'h1': {
            fontSize: '3xl',
            fontWeight: 'bold',
            mb: 6,
            mt: 8
          },
          'h2': {
            fontSize: '2xl',
            fontWeight: 'bold',
            mb: 4,
            mt: 6
          },
          'h3': {
            fontSize: 'xl',
            fontWeight: 'bold',
            mb: 3,
            mt: 5
          },
          'p': {
            mb: 4,
            lineHeight: 'tall'
          },
          'ul, ol': {
            pl: 4,
            mb: 4
          },
          'li': {
            mb: 2
          },
          'hr': {
            my: 8,
            borderColor: 'gray.200'
          },
          'a': {
            color: 'blue.500',
            textDecoration: 'underline',
            _hover: {
              color: 'blue.600'
            }
          }
        }}
      >
        <ReactMarkdown>
          {privacyPolicyContent}
        </ReactMarkdown>
      </Box>
    </Container>
  );
};

export default PrivacyPolicyPage;
