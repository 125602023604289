import React, { useState, useEffect, useCallback } from 'react';
import { auth } from '../../configurations/firebaseConfig';
import { Button, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Input, Box, VStack, Text, useDisclosure } from '@chakra-ui/react';
import { FaHospital } from 'react-icons/fa';
import FHIR from 'fhirclient';
import axios from 'axios';
import appInsights from '../../configurations/appInsights';
import CheckoutForm from '../CheckoutForm';
import { useNavigate } from 'react-router-dom';
import EpicDataParser from '../../utils/EpicDataParser';

interface Hospital {
  name: string;
  url: string;
}

const EpicConnection: React.FC = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [isHospitalModalOpen, setIsHospitalModalOpen] = useState(false);
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasPaid, setHasPaid] = useState(false);
  const { isOpen: isCheckoutOpen, onOpen: onCheckoutOpen, onClose: onCheckoutClose } = useDisclosure();
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_EPIC_PROD_CLIENT_ID
    : process.env.REACT_APP_EPIC_TEST_CLIENT_ID;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const idToken = await auth.currentUser?.getIdToken();
        const response = await axios.get('/get-user', {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        setHasPaid(response.data.hasPaid);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      axios.get('/epic-endpoints')
        .then((response: any) => {
          const hospitalList = response.data.entry.map((entry: any) => ({
            name: entry.resource.name,
            url: entry.resource.address
          }));
          setHospitals(hospitalList);
        })
        .catch((error: any) => console.error('Error fetching hospital list:', error));
    } else {
      setHospitals([
        {
          name: 'Epic FHIR Sandbox - R4',
          url: 'https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/'
        }
      ]);
    }
  }, []);

  const handleConnect = () => {
    appInsights.trackEvent({ name: 'ConnectToMyChartButtonClicked' });
    if (isConnected) {
      navigate('/dashboard');
    } else if (!hasPaid) {
      onCheckoutOpen();
    } else {
      setIsHospitalModalOpen(true);
    }
  };

  const handleHospitalSelect = (hospital: Hospital) => {
    setIsHospitalModalOpen(false);
    FHIR.oauth2.authorize({
      clientId: clientId,
      scope: 'launch/patient patient/*.read',
      redirectUri: `${window.location.origin}/chat`,
      iss: hospital.url,
    });
  };

  const filteredHospitals = hospitals.filter(hospital =>
    hospital.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchEpicData = useCallback(async (client: any) => {
    try {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      const dateParam = `date=gt${oneYearAgo.toISOString().split('T')[0]}`;

      const [
        labs,
        vitals,
        encounters,
        conditions,
        medications,
        allergies
      ] = await Promise.all([
        client.request(`Observation?category=laboratory&${dateParam}&_count=200&_sort=-date`),
        client.request(`Observation?category=vital-signs&${dateParam}&_count=50&_sort=-date&_elements=id,code,valueQuantity,effectiveDateTime,status`),
        client.request(`Encounter?${dateParam}&_count=10&_sort=-date&_elements=id,type,period,status,class`),
        client.request(`Condition?${dateParam}&_count=10&_sort=-date&_elements=id,code,onsetDateTime,clinicalStatus,verificationStatus`),
        client.request(`MedicationRequest?_count=50&_sort=-date&_elements=id,medicationCodeableConcept,dosageInstruction,authoredOn,status`),
        client.request(`AllergyIntolerance?_count=10&_sort=-date&_elements=id,code,onsetDateTime,clinicalStatus,type,category`),
      ]);

      const rawEpicData = { labs, vitals, encounters, conditions, medications, allergies };
      const epicDataParser = new EpicDataParser(rawEpicData);
      const parsedEpicData = epicDataParser.parse();

      sessionStorage.setItem('epicData', JSON.stringify(parsedEpicData));
      setIsConnected(true);
    } catch (error) {
      console.error('Error fetching EPIC data:', error);
    }
  }, []);

  useEffect(() => {
    const storedEpicData = sessionStorage.getItem('epicData');
    if (storedEpicData) {
      setIsConnected(true);
    } else {
      FHIR.oauth2.ready()
        .then(client => {
          fetchEpicData(client);
        })
        .catch(error => {
          console.error('Error initializing FHIR client:', error);
        });
    }
  }, [fetchEpicData]);

  return (
    <>
      <Button
        size={{ base: "sm", md: "md" }}
        onClick={handleConnect}
        colorScheme={isConnected ? "green" : "red"}
        leftIcon={<Icon as={FaHospital} />}
      >
        {isConnected ? "View MyChart" : "Connect to MyChart"}
      </Button>

      <Modal isOpen={isCheckoutOpen} onClose={onCheckoutClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Subscribe to Continue</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CheckoutForm />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isHospitalModalOpen} onClose={() => setIsHospitalModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Your Hospital</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Search hospitals..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb={4}
            />
            <Box maxHeight="400px" overflowY="auto">
              <VStack align="stretch" spacing={2}>
                {filteredHospitals.map((hospital, index) => (
                  <Button
                    key={index}
                    onClick={() => handleHospitalSelect(hospital)}
                    variant="outline"
                    justifyContent="flex-start"
                    whiteSpace="normal"
                    textAlign="left"
                    height="auto"
                    py={2}
                  >
                    <Text>{hospital.name}</Text>
                  </Button>
                ))}
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EpicConnection;
