import { Box, Container, Grid, Icon, Link, Stack, Text } from "@chakra-ui/react"
import { FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa"
import Logo from "../assets/Logo"
import { THEME } from "../../theme/colors"

const Footer = () => {
  return (
    <Box bg="gray.50" color="gray.700">
      <Container as={Stack} maxW="7xl" py={10}>
        <Grid templateColumns={{ base: '1fr', md: '2fr 1fr 1fr 1fr' }} gap={8}>
          <Stack spacing={6}>
            <Box>
              <Logo width="150" height="50" fillColor={THEME.primary} />
            </Box>
            <Text fontSize="sm">
              © 2025 Healoop, Inc. All rights reserved
            </Text>
            <Stack direction="row" spacing={6}>
              <Link href="https://twitter.com/healoop_" isExternal _hover={{ color: THEME.primary }}>
                <Icon as={FaTwitter} />
              </Link>
              <Link href="https://www.linkedin.com/company/healoop-inc" isExternal _hover={{ color: THEME.primary }}>
                <Icon as={FaLinkedin} />
              </Link>
              <Link href="https://instagram.com/healoop" isExternal _hover={{ color: THEME.primary }}>
                <Icon as={FaInstagram} />
              </Link>
            </Stack>
          </Stack>
          <Stack align="flex-start">
            <Text fontWeight="semibold" mb={2}>Product</Text>
            <Link href="#features" _hover={{ color: THEME.primary }}>Features</Link>
            <Link href="#pricing" _hover={{ color: THEME.primary }}>Pricing</Link>
          </Stack>
          <Stack align="flex-start">
            <Text fontWeight="semibold" mb={2}>Support</Text>
            <Link href="mailto:deji@healoop.app" _hover={{ color: THEME.primary }}>Contact Us</Link>
            <Link href="/privacy-policy" _hover={{ color: THEME.primary }}>Privacy Policy</Link>
          </Stack>
          <Stack align="flex-start">
            <Text fontWeight="semibold" mb={2}>Company</Text>
            <Link href="mailto:deji@healoop.app" _hover={{ color: THEME.primary }}>Contact</Link>
          </Stack>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
