import { useState } from "react"
import { Box, Button, Container, Flex, HStack, Icon, Link, Stack } from "@chakra-ui/react"
import { FaBars, FaTimes } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Logo from "../assets/Logo"
import SignIn from "./SignIn"
import { THEME } from "../../theme/colors"

const NavHeader = () => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  return (
    <Box position="fixed" top={0} w="full" bg="white" boxShadow="sm" zIndex={100}>
      <Container maxW="7xl">
        <Flex h={16} alignItems="center" justifyContent="space-between">
          <Box cursor="pointer" onClick={() => navigate('/')}>
            <Logo width="120" height="40" fillColor={THEME.primary} />
          </Box>
          {/* Desktop Navigation */}
          <HStack spacing={8} display={{ base: 'none', md: 'flex' }}>
            <HStack as="nav" spacing={6}>
              <Link href="#features" color="gray.700" _hover={{ color: THEME.primary }}>Features</Link>
              <Link href="#pricing" color="gray.700" _hover={{ color: THEME.primary }}>Pricing</Link>
              <Link href="mailto:deji@healoop.app" color="gray.700" _hover={{ color: THEME.primary }}>Contact</Link>
            </HStack>
            <Box sx={{
              '& > div > button': {
                width: 'auto !important',
                height: '40px !important',
                mb: '0 !important',
                px: '4',
                fontSize: 'sm'
              }
            }}>
              <SignIn buttonText="Sign in / Sign up" />
            </Box>
          </HStack>
          {/* Mobile Menu Button */}
          <Box display={{ base: 'block', md: 'none' }}>
            <Button
              variant="ghost"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Open menu"
              color={THEME.primary}
            >
              <Icon as={isMenuOpen ? FaTimes : FaBars} />
            </Button>
          </Box>
        </Flex>
        {/* Mobile Navigation */}
        <Box
          display={{ base: isMenuOpen ? 'block' : 'none', md: 'none' }}
          pb={4}
        >
          <Stack as="nav" spacing={4}>
            <Link href="#features" color="gray.700" _hover={{ color: THEME.primary }}>Features</Link>
            <Link href="#pricing" color="gray.700" _hover={{ color: THEME.primary }}>Pricing</Link>
            <Link href="mailto:deji@healoop.app" color="gray.700" _hover={{ color: THEME.primary }}>Contact</Link>
            <SignIn buttonText="Sign in / Sign up" />
          </Stack>
        </Box>
      </Container>
    </Box>
  )
}

export default NavHeader 